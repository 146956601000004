'use strict';
const appSchema = 'mindfullyapp';
const redirectMapping = {
    '10112': 'https://inner-child-healing.mindentify.com#how-to-pre-order',
    chua_lanh_dua_tre_ben_trong: 'https://inner-child-healing.mindentify.com#how-to-pre-order',
};
const os = (() => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
        return 'android';
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'ios';
    }
    return 'unknown';
})();
function getStoreFallbackUrl() {
    switch (os) {
        case 'android': {
            return 'https://play.google.com/store/apps/details?id=vn.mindfully.mindfullyapp';
        }
        case 'ios': {
            return 'https://itunes.apple.com/app/1573789196';
        }
        default: {
            return undefined;
        }
    }
}
function openStoreURL() {
    setTimeout(() => {
        const url = getStoreFallbackUrl();
        if (url) {
            window.location.assign(url);
        }
    });
}
window.addEventListener('load', () => {
    const url = new URL(window.location.href);
    url.protocol = appSchema;
    switch (os) {
        case 'android': {
            const href = `intent:${url.pathname}#Intent;scheme=${appSchema};end`;
            window.location.assign(href);
            openStoreURL();
            return;
        }
        case 'ios': {
            window.location.assign(url.href);
            openStoreURL();
            return;
        }
    }
    const pathnames = window.location.pathname.split('/');
    if (redirectMapping[pathnames[2]]) {
        window.location.replace(redirectMapping[pathnames[2]]);
        return;
    }
    window.location.replace('https://mindentify.com');
});
